.stats{box-shadow:0 5px 10px rgba(0,0,0,0.2);}
.stats .curve-image{position:absolute;left:0;right:0;bottom:100%;z-index:1;}
.stats .curve-image img{width:100%;height:auto;}
.stats .stat-text{font-size:18px;line-height:24px;}
.stats .stat-value{font-size:28px;line-height:32px;}
.stats .icon-holder{width:110px;height:110px;border-radius:100%;background:#fff;padding:23px;transition:all ease-in-out 0.3s;-o-transform: scale(1,1);-ms-transform: scale(1,1);-moz-transform: scale(1,1);-webkit-transform: scale(1,1);transform: scale(1,1);}
.stats .stats-block:hover .icon-holder{box-shadow: 0 0 17px rgba(0,0,0,0.4);-o-transform: scale(1.1,1.1);-ms-transform: scale(1.1,1.1);-moz-transform: scale(1.1,1.1);-webkit-transform: scale(1.1,1.1);transform: scale(1.1,1.1);}
.networks{background:#f9f9f9;}
.networks .network-block{background:#fff;border:1px solid #00adc2;border-top:7px solid #00adc2;border-radius:10px;box-shadow:0 0 5px rgba(0,0,0,0.2);padding:50px 25px;font-size:16px;line-height:24px;}
.networks .network-block .icon-holder{width:110px;height:110px;border-radius: 100%;overflow: hidden;border:2px solid #00adc2;box-shadow:0 4px 7px rgba(0,0,0,0.2);display:flex;justify-content:center;align-items:center;;-o-transform: scale(1,1);-ms-transform: scale(1,1);-moz-transform: scale(1,1);-webkit-transform: scale(1,1);transform: scale(1,1);transition: all ease-in-out 0.3s;}
.networks .icon-holder img{width:70%;}
.networks .network-block:hover .icon-holder{-o-transform: scale(1.1,1.1);-ms-transform: scale(1.1,1.1);-moz-transform: scale(1.1,1.1);-webkit-transform: scale(1.1,1.1);transform: scale(1.1,1.1);box-shadow: 0 0 17px rgba(0,0,0,0.4);}
.networks .network-block h2{font-size:32px;line-height:37px;font-weight: bold;}
.networks .netwrok-stats{background:#f4f4f4;padding:13px 10px;}
.networks .netwrok-stats strong,.networks .netwrok-stats span{padding:2px 0;}
.networks .netwrok-stats .network-stat{width:49%;position:relative}
.networks .netwrok-stats .network-stat:after{content:"";width:1px;position:absolute;top:0;right:-5px;bottom:0;background:#ccc;}
.networks .netwrok-stats .network-stat:last-child:after{display:none;}

@media only screen and (max-width:767px){
    .stats .stats-block{margin:0 0 30px;}
    .stats .row .col-md-4:last-child .stats-block{margin:0;}
    .networks .network-block{margin:0 0 30px;}
}
@media only screen and (max-width:575px){
    .networks .network-block{padding:50px 10px;}
    .networks .netwrok-stats .network-stat{width:100%;margin:0 0 10px;}
    .networks .netwrok-stats .network-stat:after{width:100%;height:1px;top:auto;bottom:-5px;left:0;right:0;}
    .networks .netwrok-stats .network-stat:last-child{margin:0;}
}