.main-banner{height:100vh;padding:100px 0 0;font-size:35px;background: #27223d;background: -moz-linear-gradient(45deg,  #27223d 0%, #143567 100%);background: -webkit-linear-gradient(45deg,  #27223d 0%,#143567 100%);background: linear-gradient(45deg,  #27223d 0%,#143567 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27223d', endColorstr='#143567',GradientType=1 );position: inherit;}
.main-banner:before{content:"";width:100%;height:100%;position: absolute;left:0;top:0;background-image:url(../../assets/images/bg-lines.png);background-size:cover;background-position:center center;}
.main-banner .container,.main-banner .container-fluid,.main-banner .row{height:100%;}
#banner-img canvas{width:100% !important;height:auto !important;}
.main-banner h1{font-size:125px;font-weight:bold;margin:0 0 15px;}
.main-banner .banner-img{max-width:80%;margin:0 auto;}
@media only screen and (max-width:1899px){
    .main-banner h1{font-size:98px;}
}
@media only screen and (max-width:1399px){
    .main-banner{font-size:28px;line-height:34px;}
    .main-banner h1{font-size:85px;}
}
@media only screen and (max-width:1199px){
    .main-banner{font-size:24px;line-height:28px;}
    .main-banner h1{font-size:75px;line-height:85px;}
}
@media only screen and (max-width:991px){
    .main-banner{font-size:22px;line-height:25px;padding:70px 0 0;}
    .main-banner h1{font-size:50px;line-height:60px;}
}
@media only screen and (max-width:767px){
    .main-banner{height:auto;padding:135px 0 80px;}
}
@media only screen and (max-width:576px){
    .main-banner h1{font-size:36px;line-height:45px;}
    .main-banner .banner-img{max-width:95%;}
}