.how-work .how-work-block{min-height:328px;margin:0 0 30px;font-size:16px;line-height:20px;border:1px solid #00adc2;border-top:7px solid #00adc2;box-shadow:0 0 5px rgba(0,0,0,0.20);border-radius:10px;padding:20px 15px 20px;background:#fff;}
.how-work .icon-holder{width:110px;height:110px;border-radius: 100%;overflow: hidden;border:2px solid #00adc2;box-shadow:0 4px 7px rgba(0,0,0,0.2);display:flex;justify-content:center;align-items:center;padding-top:19px;padding-right:7px;padding-left:6px;transition:all ease-in-out 0.3s;}
.how-work .icon-holder img{width:70%;}
.how-work .how-work-block:hover .icon-holder{-o-transform: scale(1.1,1.1);-ms-transform: scale(1.1,1.1);-moz-transform: scale(1.1,1.1);-webkit-transform: scale(1.1,1.1);transform: scale(1.1,1.1);box-shadow: 0 0 17px rgba(0,0,0,0.4);}
.how-work .how-work-block h2{font-size:32px;line-height:37px;font-weight:bold;}
@media only screen and (max-width:991px){
    .how-work .how-work-block{height:auto;min-height:370px;margin:0 0 30px;}
}
@media only screen and (max-width:767px){
    .how-work .how-work-block{min-height:unset;}
}