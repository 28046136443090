#footer{padding:100px 0 0;clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);background: #27223d;background: -moz-linear-gradient(45deg,  #27223d 0%, #143567 100%);background: -webkit-linear-gradient(45deg,  #27223d 0%,#143567 100%);background: linear-gradient(45deg,  #27223d 0%,#143567 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27223d', endColorstr='#143567',GradientType=1);}
#footer .footer-logo{width:150px;}
#footer h3{font-size:22px;line-height:26px;}
#footer .footer-nav{font-size:14px;line-height:18px;}
#footer .footer-nav li{margin:0 0 8px;}
#footer .footer-nav a{color:#fff;padding:0 0 7px;position:relative;display:inline-block;vertical-align:top;}
#footer .footer-nav a:after{content:"";width:0;height:2px;position:absolute;left:0;bottom:0;right:0;background:#fff;transition: all ease-in-out 0.2s;}
#footer .footer-nav a:hover:after{width:100%;}
#footer .social-links li{margin:0 10px 0 0;width:30px;height:30px;display:flex;}
#footer .social-links li a{color:#fff;display:flex;height:100%;justify-content: center;align-items:center;border:1px solid #fff;border-radius:4px;transition: all ease-in-out 0.3s;}
#footer .social-links li a:hover{background:#fff;color:#1564b1;border-radius: 10px;}
#footer .copyright-block{padding:20px 0 20px;font-size:14px;line-height:17px;border-top:1px solid rgba(255,255,255,0.2);color:#fff;}
#footer .copyright-block p{margin:0;}
@media only screen and (max-width:991px){
    #footer{clip-path:polygon(0 10%, 100% 0, 100% 100%, 0% 100%);}
    .footer-col{margin:0 0 30px;}
    
}
@media only screen and (max-width:576px){
    #footer{padding:80px 0 0;clip-path:polygon(0 5%, 100% 0, 100% 100%, 0% 100%);}
}