.stakes .nav.nav-tabs{border-bottom:none;margin:0 0 30px;}
.stakes .nav.nav-tabs a{width:110px;border:1px solid #cccc;margin:0 10px 0 0;border-radius:25px;color:#999;font-size: 16px;line-height: 19px;text-align:center;padding: 12px 25px;transition:all ease-in-out 0.2s;background:#ccc;}
.stakes .nav.nav-tabs a.active{background: #2d6ff5;color: #fff;border-color:#2d6ff5;box-shadow:0 5px 5px rgba(0,0,0,0.20);}
.stakes .wrap-unwrap-form{border:1px solid #ccc;border-radius:5px;font-size:14px;padding:0 70px 0 0;}
.stakes .wrap-unwrap-form .select-holder{border-right:1px solid #ccc;padding:2px 10px 1px;width:140px;}
.stakes .wrap-unwrap-form .react-select__control{border:none !important;width:100%;background:none !important;}
.stakes .wrap-unwrap-form .react-select__indicator-separator {display:none !important;}
.stakes .wrap-unwrap-form .react-select__control--is-focused{box-shadow: none !important;}
.stakes .wrap-unwrap-form .react-select__menu{width: calc(100% + 20px) !important;top:calc(100% + 4px) !important;left: -10px !important;border-radius: 0 !important;margin-top:0;}
.stakes .wrap-unwrap-form .react-select__option{cursor: pointer;}
.stakes .wrap-unwrap-form .react-select__single-value{color:#000;}
.stakes .wrap-unwrap-form .input-holder{padding:2px 10px 1px;}
.stakes .wrap-unwrap-form .input-holder input[type="number"]{width:100%;height:100%;border:none;background:none;color:#000;}
.stakes .wrap-unwrap-form .input-holder input[type="number"]:focus{border:none;box-shadow: none;outline:none;appearance: none;}
.stakes .wrap-unwrap-form .input-holder input::-webkit-input-placeholder {color:    #000;}
.stakes .wrap-unwrap-form .input-holder input::-moz-placeholder {color:    #000;opacity:  1;}
.stakes .wrap-unwrap-form .input-holder input::-moz-placeholder {color:    #000;opacity:  1;}
.stakes .wrap-unwrap-form .input-holder input::-ms-input-placeholder {color:    #000;}
.stakes .wrap-unwrap-form .input-holder input::-ms-input-placeholder {color:    #000;}
.stakes .wrap-unwrap-form .input-holder input::placeholder { color:    #000;}
.stakes .wrap-unwrap-form .max-btn,.stakes .wrap-unwrap-form .max-btn:focus{position:absolute;top:10px;right:10px;width:70px;height:calc(100% - 20px);border:none;border-radius:5px;background:#f0f5ff;font-size:14px;line-height:17px;font-weight:bold;color:#2d6ff5;transition: all ease-in-out 0.2s;}
.stakes .wrap-unwrap-form .max-btn:hover{background:#d2dffa;color:#2d6ff5;}
.stakes .services-list{background:#fff;border-radius:10px;font-size:14px;line-height:18px;padding:15px;}
@media only screen and (max-width:575px){
    .stakes .wrap-unwrap-form{padding:0;}
    .stakes .wrap-unwrap-form .select-holder{width:100%;border-right:none;border-bottom:1px solid #ccc;}
    .stakes .wrap-unwrap-form .input-holder{padding:12px 88px 14px 18px;position: relative;}
}