#header{position:absolute;left:0;top:0;right:0;z-index:2;}
#header .header-top{padding:15px 0;border-bottom:1px solid #fff;}
#header .header-bottom{padding:15px 0 0;}
.navbar{font-weight:500;padding:0 40px 0 0;}
.navbar-light .navbar-nav .nav-link{color:#fff;position:relative;}
.navbar-light .navbar-nav .nav-link:after{content:"";width:0;height:2px;position:absolute;left:0;bottom:0;background:#fff;transition: all ease-in-out 0.3s;}
.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{color:#fff;}
.navbar-light .navbar-nav .nav-link:hover:after,
.navbar-light .navbar-nav .active > .nav-link:after{width:100%;}
.navbar-expand-lg .navbar-nav .nav-link{padding:0 0 5px;}
.navbar .navbar-nav .nav-item{margin:0 0 0 30px;}

@media only screen and (max-width:991px){
    .pos-stat{position: static;}
    .header-bottom{position:relative;}
    .navbar-light .navbar-toggler-icon{background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")}
    .navbar-light .navbar-toggler{padding:0;border:none;position:absolute;top:calc(50% + 7px);right:0;-o-transform:translateY(-50%);-ms-transform:translateY(-50%);-moz-transform:translateY(-50%);-webkit-transform:translateY(-50%);transform:translateY(-50%);color:#fff;}
    .navbar-collapse{width:100%;position:absolute;left:0;top:62px;background:#fff;z-index:1;}
    .navbar .navbar-nav .nav-item{margin:0;}
    .navbar-expand-lg .navbar-nav .nav-link{padding:8px 10px 12px;text-align:left;}
    .navbar-light .navbar-nav .nav-link:after{display:none;}
    .navbar-light .navbar-nav .nav-link{color:#2d6ff5;;}
    .navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,
    .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{background:#2d6ff5;color:#f0f5ff;}
}
@media only screen and (max-width:767px){
    .header-bottom{text-align: left;;}
    .logo{max-width:100px;}
}