@import url(https://fonts.googleapis.com/css2?family=Metrophobic&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{min-width:320px;font-family:'Metrophobic', sans-serif;font-size:18px;line-height:26px;color:#222;background:#f9f9f9;}
a,a:hover{text-decoration:none;}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,ul,ol,dl{margin:0;}
h2,.h2{font-size:48px;line-height:55px;}
.content-section{padding:100px 0;}
.overlay{position:absolute;left:0;top:0;right:0;bottom:0;}
.overlay.black-overlay{background:rgba(0,0,0,0.7);}
.bg-purple{background: #00adc2;}
.bg-grey{background:#e4e4e4;}
.mfileBtn,.mfileBtn:focus{font-size:16px;line-height:19px;padding:12px 25px;transition: all ease-in-out 0.34s;border:1px solid;box-shadow:none;outline:none;border-radius:25px;box-shadow: 0 5px 5px rgba(0,0,0,0.2);}
.mfileBtn:hover{border-radius: 5px;}
.btn-white-outline,.btn-white-outline:focus{border-color:#fff;background-color:#fff;;color:#000;}
.btn-white-outline:hover{background-color:#fff;color:#000}
.btn-primary,.btn-primary:focus{border-color:#2d6ff5;background:#2d6ff5;color:#fff;}
.btn-primary:hover{background:#2d6ff5;color:#fff;}
.btn-secondary,.btn-secondary:focus{border-color:#000;background:#000;color:#fff;}
.heading-section{margin:0 0 70px;}
.btn-secondary:hover{background:#000;color:#fff;}
@media only screen and (max-width:1199px){
    h1,.h1{font-size:35px;line-height:45px;}
    .content-section{padding:80px 0;}
    .heading-section{margin:0 0 55px;}
}
@media only screen and (max-width:991px){
    h1,.h1{font-size:30px;line-height:38px;}
    .content-section{padding:60px 0;}
    .heading-section{margin:0 0 40px;}
}
@media only screen and (max-width:767px){
    h1,.h1{font-size:22px;line-height:25px;}
    .content-section{padding:50px 0;}
    .heading-section{margin:0 0 35px;}
    h2, .h2 {font-size: 42px;line-height: 55px;}
}
@media only screen and (max-width:575px){
    body{font-size:14px;line-height:22px;}
    .content-section{padding:50px 0;}
    .heading-section{margin:0 0 30px;}
    h2,.h2{font-size:34px;line-height:40px;}
}
#header{position:absolute;left:0;top:0;right:0;z-index:2;}
#header .header-top{padding:15px 0;border-bottom:1px solid #fff;}
#header .header-bottom{padding:15px 0 0;}
.navbar{font-weight:500;padding:0 40px 0 0;}
.navbar-light .navbar-nav .nav-link{color:#fff;position:relative;}
.navbar-light .navbar-nav .nav-link:after{content:"";width:0;height:2px;position:absolute;left:0;bottom:0;background:#fff;transition: all ease-in-out 0.3s;}
.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{color:#fff;}
.navbar-light .navbar-nav .nav-link:hover:after,
.navbar-light .navbar-nav .active > .nav-link:after{width:100%;}
.navbar-expand-lg .navbar-nav .nav-link{padding:0 0 5px;}
.navbar .navbar-nav .nav-item{margin:0 0 0 30px;}

@media only screen and (max-width:991px){
    .pos-stat{position: static;}
    .header-bottom{position:relative;}
    .navbar-light .navbar-toggler-icon{background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")}
    .navbar-light .navbar-toggler{padding:0;border:none;position:absolute;top:calc(50% + 7px);right:0;-webkit-transform:translateY(-50%);transform:translateY(-50%);color:#fff;}
    .navbar-collapse{width:100%;position:absolute;left:0;top:62px;background:#fff;z-index:1;}
    .navbar .navbar-nav .nav-item{margin:0;}
    .navbar-expand-lg .navbar-nav .nav-link{padding:8px 10px 12px;text-align:left;}
    .navbar-light .navbar-nav .nav-link:after{display:none;}
    .navbar-light .navbar-nav .nav-link{color:#2d6ff5;;}
    .navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,
    .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{background:#2d6ff5;color:#f0f5ff;}
}
@media only screen and (max-width:767px){
    .header-bottom{text-align: left;;}
    .logo{max-width:100px;}
}
.main-banner{height:100vh;padding:100px 0 0;font-size:35px;background: #27223d;background: linear-gradient(45deg,  #27223d 0%,#143567 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27223d', endColorstr='#143567',GradientType=1 );position: inherit;}
.main-banner:before{content:"";width:100%;height:100%;position: absolute;left:0;top:0;background-image:url(/static/media/bg-lines.c8b524fb.png);background-size:cover;background-position:center center;}
.main-banner .container,.main-banner .container-fluid,.main-banner .row{height:100%;}
#banner-img canvas{width:100% !important;height:auto !important;}
.main-banner h1{font-size:125px;font-weight:bold;margin:0 0 15px;}
.main-banner .banner-img{max-width:80%;margin:0 auto;}
@media only screen and (max-width:1899px){
    .main-banner h1{font-size:98px;}
}
@media only screen and (max-width:1399px){
    .main-banner{font-size:28px;line-height:34px;}
    .main-banner h1{font-size:85px;}
}
@media only screen and (max-width:1199px){
    .main-banner{font-size:24px;line-height:28px;}
    .main-banner h1{font-size:75px;line-height:85px;}
}
@media only screen and (max-width:991px){
    .main-banner{font-size:22px;line-height:25px;padding:70px 0 0;}
    .main-banner h1{font-size:50px;line-height:60px;}
}
@media only screen and (max-width:767px){
    .main-banner{height:auto;padding:135px 0 80px;}
}
@media only screen and (max-width:576px){
    .main-banner h1{font-size:36px;line-height:45px;}
    .main-banner .banner-img{max-width:95%;}
}
.stats{box-shadow:0 5px 10px rgba(0,0,0,0.2);}
.stats .curve-image{position:absolute;left:0;right:0;bottom:100%;z-index:1;}
.stats .curve-image img{width:100%;height:auto;}
.stats .stat-text{font-size:18px;line-height:24px;}
.stats .stat-value{font-size:28px;line-height:32px;}
.stats .icon-holder{width:110px;height:110px;border-radius:100%;background:#fff;padding:23px;transition:all ease-in-out 0.3s;-webkit-transform: scale(1,1);transform: scale(1,1);}
.stats .stats-block:hover .icon-holder{box-shadow: 0 0 17px rgba(0,0,0,0.4);-webkit-transform: scale(1.1,1.1);transform: scale(1.1,1.1);}
.networks{background:#f9f9f9;}
.networks .network-block{background:#fff;border:1px solid #00adc2;border-top:7px solid #00adc2;border-radius:10px;box-shadow:0 0 5px rgba(0,0,0,0.2);padding:50px 25px;font-size:16px;line-height:24px;}
.networks .network-block .icon-holder{width:110px;height:110px;border-radius: 100%;overflow: hidden;border:2px solid #00adc2;box-shadow:0 4px 7px rgba(0,0,0,0.2);display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;-webkit-transform: scale(1,1);transform: scale(1,1);transition: all ease-in-out 0.3s;}
.networks .icon-holder img{width:70%;}
.networks .network-block:hover .icon-holder{-webkit-transform: scale(1.1,1.1);transform: scale(1.1,1.1);box-shadow: 0 0 17px rgba(0,0,0,0.4);}
.networks .network-block h2{font-size:32px;line-height:37px;font-weight: bold;}
.networks .netwrok-stats{background:#f4f4f4;padding:13px 10px;}
.networks .netwrok-stats strong,.networks .netwrok-stats span{padding:2px 0;}
.networks .netwrok-stats .network-stat{width:49%;position:relative}
.networks .netwrok-stats .network-stat:after{content:"";width:1px;position:absolute;top:0;right:-5px;bottom:0;background:#ccc;}
.networks .netwrok-stats .network-stat:last-child:after{display:none;}

@media only screen and (max-width:767px){
    .stats .stats-block{margin:0 0 30px;}
    .stats .row .col-md-4:last-child .stats-block{margin:0;}
    .networks .network-block{margin:0 0 30px;}
}
@media only screen and (max-width:575px){
    .networks .network-block{padding:50px 10px;}
    .networks .netwrok-stats .network-stat{width:100%;margin:0 0 10px;}
    .networks .netwrok-stats .network-stat:after{width:100%;height:1px;top:auto;bottom:-5px;left:0;right:0;}
    .networks .netwrok-stats .network-stat:last-child{margin:0;}
}
.note-block{-webkit-clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);color:#000;}
.how-work .how-work-block{min-height:328px;margin:0 0 30px;font-size:16px;line-height:20px;border:1px solid #00adc2;border-top:7px solid #00adc2;box-shadow:0 0 5px rgba(0,0,0,0.20);border-radius:10px;padding:20px 15px 20px;background:#fff;}
.how-work .icon-holder{width:110px;height:110px;border-radius: 100%;overflow: hidden;border:2px solid #00adc2;box-shadow:0 4px 7px rgba(0,0,0,0.2);display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;padding-top:19px;padding-right:7px;padding-left:6px;transition:all ease-in-out 0.3s;}
.how-work .icon-holder img{width:70%;}
.how-work .how-work-block:hover .icon-holder{-webkit-transform: scale(1.1,1.1);transform: scale(1.1,1.1);box-shadow: 0 0 17px rgba(0,0,0,0.4);}
.how-work .how-work-block h2{font-size:32px;line-height:37px;font-weight:bold;}
@media only screen and (max-width:991px){
    .how-work .how-work-block{height:auto;min-height:370px;margin:0 0 30px;}
}
@media only screen and (max-width:767px){
    .how-work .how-work-block{min-height:unset;}
}
#footer{padding:100px 0 0;-webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);background: #27223d;background: linear-gradient(45deg,  #27223d 0%,#143567 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27223d', endColorstr='#143567',GradientType=1);}
#footer .footer-logo{width:150px;}
#footer h3{font-size:22px;line-height:26px;}
#footer .footer-nav{font-size:14px;line-height:18px;}
#footer .footer-nav li{margin:0 0 8px;}
#footer .footer-nav a{color:#fff;padding:0 0 7px;position:relative;display:inline-block;vertical-align:top;}
#footer .footer-nav a:after{content:"";width:0;height:2px;position:absolute;left:0;bottom:0;right:0;background:#fff;transition: all ease-in-out 0.2s;}
#footer .footer-nav a:hover:after{width:100%;}
#footer .social-links li{margin:0 10px 0 0;width:30px;height:30px;display:-webkit-flex;display:flex;}
#footer .social-links li a{color:#fff;display:-webkit-flex;display:flex;height:100%;-webkit-justify-content: center;justify-content: center;-webkit-align-items:center;align-items:center;border:1px solid #fff;border-radius:4px;transition: all ease-in-out 0.3s;}
#footer .social-links li a:hover{background:#fff;color:#1564b1;border-radius: 10px;}
#footer .copyright-block{padding:20px 0 20px;font-size:14px;line-height:17px;border-top:1px solid rgba(255,255,255,0.2);color:#fff;}
#footer .copyright-block p{margin:0;}
@media only screen and (max-width:991px){
    #footer{-webkit-clip-path:polygon(0 10%, 100% 0, 100% 100%, 0% 100%);clip-path:polygon(0 10%, 100% 0, 100% 100%, 0% 100%);}
    .footer-col{margin:0 0 30px;}
    
}
@media only screen and (max-width:576px){
    #footer{padding:80px 0 0;-webkit-clip-path:polygon(0 5%, 100% 0, 100% 100%, 0% 100%);clip-path:polygon(0 5%, 100% 0, 100% 100%, 0% 100%);}
}

.page-banner{height:350px;padding:75px 0 0;background: #27223d;background: linear-gradient(45deg,  #27223d 0%,#143567 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27223d', endColorstr='#143567',GradientType=1 );position:relative;}
.page-banner:before{content:"";width:100%;height:100%;position: absolute;left:0;top:0;background-image:url(/static/media/bg-lines.c8b524fb.png);background-size:cover;background-position:center center;}
.page-banner .container,.page-banner .row{height:100%;}
@media only screen and (max-width:1199px){
	.page-banner{height:280px;}
}
@media only screen and (max-width:991px){
	.page-banner{height:200px;padding:70px 0 0;}
}
@media only screen and (max-width:575px){
	.page-banner{height:150px;}
}
.faqs .card,
.faqs .accordion>.card:not(:last-of-type){border:solid #2d6ff5;border-width:0 0 0 4px;border-radius:5px;padding:25px 20px;background:#f0f5ff;}
.faqs .accordion>.card:not(:first-of-type){border-radius: 5px;}
.faqs .accordion .card .card-header button,.faqs .accordion .card .card-header button:focus{background:none;padding:0;font-size:16px;line-height:19px;font-weight:bold;box-shadow:none;outline:none;text-decoration:none;color:#2d6ff5;}
.faqs .accordion .card .card-header button:hover{text-decoration:none;}
.faqs .accordion .card .card-header{border-bottom:none;background:none;position: relative;}
.faqs .accordion .card .card-body{background:#fff;}
.stakes .nav.nav-tabs{border-bottom:none;margin:0 0 30px;}
.stakes .nav.nav-tabs a{width:110px;border:1px solid #cccc;margin:0 10px 0 0;border-radius:25px;color:#999;font-size: 16px;line-height: 19px;text-align:center;padding: 12px 25px;transition:all ease-in-out 0.2s;background:#ccc;}
.stakes .nav.nav-tabs a.active{background: #2d6ff5;color: #fff;border-color:#2d6ff5;box-shadow:0 5px 5px rgba(0,0,0,0.20);}
.stakes .wrap-unwrap-form{border:1px solid #ccc;border-radius:5px;font-size:14px;padding:0 70px 0 0;}
.stakes .wrap-unwrap-form .select-holder{border-right:1px solid #ccc;padding:2px 10px 1px;width:140px;}
.stakes .wrap-unwrap-form .react-select__control{border:none !important;width:100%;background:none !important;}
.stakes .wrap-unwrap-form .react-select__indicator-separator {display:none !important;}
.stakes .wrap-unwrap-form .react-select__control--is-focused{box-shadow: none !important;}
.stakes .wrap-unwrap-form .react-select__menu{width: calc(100% + 20px) !important;top:calc(100% + 4px) !important;left: -10px !important;border-radius: 0 !important;margin-top:0;}
.stakes .wrap-unwrap-form .react-select__option{cursor: pointer;}
.stakes .wrap-unwrap-form .react-select__single-value{color:#000;}
.stakes .wrap-unwrap-form .input-holder{padding:2px 10px 1px;}
.stakes .wrap-unwrap-form .input-holder input[type="number"]{width:100%;height:100%;border:none;background:none;color:#000;}
.stakes .wrap-unwrap-form .input-holder input[type="number"]:focus{border:none;box-shadow: none;outline:none;-webkit-appearance: none;appearance: none;}
.stakes .wrap-unwrap-form .input-holder input::-webkit-input-placeholder {color:    #000;}
.stakes .wrap-unwrap-form .input-holder input:-ms-input-placeholder { color:    #000;}
.stakes .wrap-unwrap-form .input-holder input::placeholder { color:    #000;}
.stakes .wrap-unwrap-form .max-btn,.stakes .wrap-unwrap-form .max-btn:focus{position:absolute;top:10px;right:10px;width:70px;height:calc(100% - 20px);border:none;border-radius:5px;background:#f0f5ff;font-size:14px;line-height:17px;font-weight:bold;color:#2d6ff5;transition: all ease-in-out 0.2s;}
.stakes .wrap-unwrap-form .max-btn:hover{background:#d2dffa;color:#2d6ff5;}
.stakes .services-list{background:#fff;border-radius:10px;font-size:14px;line-height:18px;padding:15px;}
@media only screen and (max-width:575px){
    .stakes .wrap-unwrap-form{padding:0;}
    .stakes .wrap-unwrap-form .select-holder{width:100%;border-right:none;border-bottom:1px solid #ccc;}
    .stakes .wrap-unwrap-form .input-holder{padding:12px 88px 14px 18px;position: relative;}
}
.wrap-page .nav.nav-tabs{border-bottom:none;margin:0 0 30px;}
.wrap-page .nav.nav-tabs a{width:110px;border:1px solid #cccc;margin:0 10px 0 0;border-radius:25px;color:#999;font-size: 16px;line-height: 19px;text-align:center;padding: 12px 25px;transition:all ease-in-out 0.2s;background:#ccc;}
.wrap-page .nav.nav-tabs a.active{background: #2d6ff5;color: #fff;border-color:#2d6ff5;box-shadow:0 5px 5px rgba(0,0,0,0.20);}
.wrap-page .wrap-unwrap-form{border:1px solid #ccc;border-radius:5px;font-size:14px;padding:0 70px 0 0;}
.wrap-page .wrap-unwrap-form .select-holder{border-right:1px solid #ccc;padding:2px 10px 1px;width:140px;}
.wrap-page .wrap-unwrap-form .react-select__control{border:none !important;width:100%;background:none !important;}
.wrap-page .wrap-unwrap-form .react-select__indicator-separator {display:none !important;}
.wrap-page .wrap-unwrap-form .react-select__control--is-focused{box-shadow: none !important;}
.wrap-page .wrap-unwrap-form .react-select__menu{width: calc(100% + 20px) !important;top:calc(100% + 4px) !important;left: -10px !important;border-radius: 0 !important;margin-top:0;}
.wrap-page .wrap-unwrap-form .react-select__option{cursor: pointer;}
.wrap-page .wrap-unwrap-form .react-select__single-value{color:#000;}
.wrap-page .wrap-unwrap-form .input-holder{padding:2px 10px 1px;}
.wrap-page .wrap-unwrap-form .input-holder input[type="number"]{width:100%;height:100%;border:none;background:none;color:#000;}
.wrap-page .wrap-unwrap-form .input-holder input[type="number"]:focus{border:none;box-shadow: none;outline:none;-webkit-appearance: none;appearance: none;}
.wrap-page .wrap-unwrap-form .input-holder input::-webkit-input-placeholder {color:    #000;}
.wrap-page .wrap-unwrap-form .input-holder input:-ms-input-placeholder { color:    #000;}
.wrap-page .wrap-unwrap-form .input-holder input::placeholder { color:    #000;}
.wrap-page .wrap-unwrap-form .max-btn,.wrap-page .wrap-unwrap-form .max-btn:focus{position:absolute;top:10px;right:10px;width:70px;height:calc(100% - 20px);border:none;border-radius:5px;background:#f0f5ff;font-size:14px;line-height:17px;font-weight:bold;color:#2d6ff5;transition: all ease-in-out 0.2s;}
.wrap-page .wrap-unwrap-form .max-btn:hover{background:#d2dffa;color:#2d6ff5;}
.wrap-page .services-list{background:#fff;border-radius:10px;font-size:14px;line-height:18px;padding:15px;}
@media only screen and (max-width:575px){
    .wrap-page .wrap-unwrap-form{padding:0;}
    .wrap-page .wrap-unwrap-form .select-holder{width:100%;border-right:none;border-bottom:1px solid #ccc;}
    .wrap-page .wrap-unwrap-form .input-holder{padding:12px 88px 14px 18px;position: relative;}
}
