.page-banner{height:350px;padding:75px 0 0;background: #27223d;background: -moz-linear-gradient(45deg,  #27223d 0%, #143567 100%);background: -webkit-linear-gradient(45deg,  #27223d 0%,#143567 100%);background: linear-gradient(45deg,  #27223d 0%,#143567 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27223d', endColorstr='#143567',GradientType=1 );position:relative;}
.page-banner:before{content:"";width:100%;height:100%;position: absolute;left:0;top:0;background-image:url(../../assets/images/bg-lines.png);background-size:cover;background-position:center center;}
.page-banner .container,.page-banner .row{height:100%;}
@media only screen and (max-width:1199px){
	.page-banner{height:280px;}
}
@media only screen and (max-width:991px){
	.page-banner{height:200px;padding:70px 0 0;}
}
@media only screen and (max-width:575px){
	.page-banner{height:150px;}
}