@import url('https://fonts.googleapis.com/css2?family=Metrophobic&display=swap');
body{min-width:320px;font-family:'Metrophobic', sans-serif;font-size:18px;line-height:26px;color:#222;background:#f9f9f9;}
a,a:hover{text-decoration:none;}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,ul,ol,dl{margin:0;}
h2,.h2{font-size:48px;line-height:55px;}
.content-section{padding:100px 0;}
.overlay{position:absolute;left:0;top:0;right:0;bottom:0;}
.overlay.black-overlay{background:rgba(0,0,0,0.7);}
.bg-purple{background: #00adc2;}
.bg-grey{background:#e4e4e4;}
.mfileBtn,.mfileBtn:focus{font-size:16px;line-height:19px;padding:12px 25px;transition: all ease-in-out 0.34s;border:1px solid;box-shadow:none;outline:none;border-radius:25px;box-shadow: 0 5px 5px rgba(0,0,0,0.2);}
.mfileBtn:hover{border-radius: 5px;}
.btn-white-outline,.btn-white-outline:focus{border-color:#fff;background-color:#fff;;color:#000;}
.btn-white-outline:hover{background-color:#fff;color:#000}
.btn-primary,.btn-primary:focus{border-color:#2d6ff5;background:#2d6ff5;color:#fff;}
.btn-primary:hover{background:#2d6ff5;color:#fff;}
.btn-secondary,.btn-secondary:focus{border-color:#000;background:#000;color:#fff;}
.heading-section{margin:0 0 70px;}
.btn-secondary:hover{background:#000;color:#fff;}
@media only screen and (max-width:1199px){
    h1,.h1{font-size:35px;line-height:45px;}
    .content-section{padding:80px 0;}
    .heading-section{margin:0 0 55px;}
}
@media only screen and (max-width:991px){
    h1,.h1{font-size:30px;line-height:38px;}
    .content-section{padding:60px 0;}
    .heading-section{margin:0 0 40px;}
}
@media only screen and (max-width:767px){
    h1,.h1{font-size:22px;line-height:25px;}
    .content-section{padding:50px 0;}
    .heading-section{margin:0 0 35px;}
    h2, .h2 {font-size: 42px;line-height: 55px;}
}
@media only screen and (max-width:575px){
    body{font-size:14px;line-height:22px;}
    .content-section{padding:50px 0;}
    .heading-section{margin:0 0 30px;}
    h2,.h2{font-size:34px;line-height:40px;}
}